import * as React from "react"
import '../neat-basics/index.scss'
import "./theming.scss"
import "../styles/styles.scss"
import SubPageHeader from "../modules/sub-page-header"
import Page from "../modules/page"
import Section from "../modules/section";
import Navigation from "../modules/navigation";
import Footer from "../modules/footer";
import {graphql} from "gatsby";
import Image from "../modules/image";
import {AboutPageProps} from "../types";
import MobileNavigation from "../modules/mobile-navigation";
import Main from "../modules/main";

const AboutPage = (page: {data: {about:  AboutPageProps}}) => {
  const about = page.data.about;
  return (<>
    <Main title="About">
      <Page>
        <SubPageHeader title="About">
          <Navigation activePage="about"/>
          <MobileNavigation activePage="about"/>
        </SubPageHeader>
        <Section>
          <div className="article-text">
            <div className="article-text__container">
              <Image image={about.frontmatter.image}/>
              <div dangerouslySetInnerHTML={{__html: about.html}}/>
        </div>
          </div>
        </Section>
      </Page>
      <Footer/>
    </Main></>
  )
}

export const pageQuery = graphql`
  query AboutPage {
    about: markdownRemark(fields: {slug: {eq: "/single-pages/about/"}}) {
      html
      frontmatter {
        title
        image {
           childImageSharp {
             gatsbyImageData(width: 1500)
           }
        }
      }
    }
  }
`

export default AboutPage
